import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { SvgIcon } from 'src/components/atoms/SvgIcon';
// import { CityPopup } from 'src/components/popups/CityPopup';
import HeaderModal from 'src/components/popups/HeaderModal';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { resetImagesArr, setLng, setLocation } from 'src/store/app/actions';
import { resetCategories } from 'src/store/categories/actions';
import { setOrder } from 'src/store/order/actions';
import { IRootState } from 'src/types/store-types';
import { scrollToTop } from 'src/utils/helpers';
import SearchInput from '../SearchInput';
import SocialIcons from '../SocialIcons';
import './styles.scss';

const Header: FC = () => {
  const { t, i18n } = useTranslation();
  const resetCategoriesAction = useConnectedAction(resetCategories);
  const resetImagesArrAction = useConnectedAction(resetImagesArr);
  const setLngAction = useConnectedAction(setLng);
  const setLocationAction = useConnectedAction(setLocation);
  const setOrderDataAction = useConnectedAction(setOrder);

  let { lng } = useSelector((state: IRootState) => state.app);
  const { cities } = useSelector((state: IRootState) => state.app);
  const { cartCount } = useSelector((state: IRootState) => state.tours);

  lng = lng.toLowerCase();
  const [activetab, setActive] = useState(lng);
  const [visibleModal, setVisibleModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = (): void => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // const [visible, setVisible] = useState(false);
  const handleSelectLng = (lng: string): void => {
    setLngAction(lng);
    i18n.changeLanguage(lng);
    setActive(lng);
  };
  const toogleList = (): void => {
    setVisibleModal((state: boolean) => !state);
  };

  const closeHeaderModal = (): void => {
    if (visibleModal) {
      setVisibleModal(false);
    }
  };

  const onLogoClick = (): void => {
    resetImagesArrAction();
    closeHeaderModal();
    scrollToTop();
  };

  return (
    <header className="header">
      <div className="header-logo-container">
        <Link to="/" onClick={onLogoClick} aria-label="tourest.online">
          <SvgIcon className="logo" src={'/images/logo.svg'} />
        </Link>
      </div>
      <div className="header-rigth-container">
        <div className="header-social-icons-conatiner" style={{ display: width > 800 ? 'flex' : 'none' }}>
          <SocialIcons className="header-social-icons" color="#000" />
        </div>
        <SearchInput />

        {/* <div
          className="header-content city-container link"
          onClick={() => setVisible(true)}
          style={{ display: width > 800 ? 'flex' : 'none' }}
        >
          <div className="header-content-tab">
            {location ? capitalizeFirstLetter(location) : t('chooseCity')}
            <div className="header-content-border-bottom"></div>
          </div>
        </div> */}

        {/* <CityPopup
          visible={visible}
          setVisible={setVisible}
          cities={cities}
          setCity={setLocationAction}
          resetCategories={resetCategoriesAction}
          setOrderData={setOrderDataAction}
        /> */}
        <div className="header-content left link" style={{ display: width > 800 ? 'flex' : 'none' }}>
          {/* <div className="header-content-tab" style={{ display: width > 1280 ? 'flex' : 'none' }}>
            <Link className="link" to={`${location}/taxi`}>
              {t('taxi')}
            </Link>
            <div className="header-content-border-bottom"></div>
          </div> */}
          <div className="header-content-tab">
            <Link className="link" to={`/referrer`}>
              {t('becomePartner')}
            </Link>
            <div className="header-content-border-bottom"></div>
          </div>

          <div className="header-content-tab">
            <Link className="link" to={`/blog`}>
              {t('blog')}
            </Link>
            <div className="header-content-border-bottom"></div>
          </div>
          <div className="header-content-tab">
            <Link className="link" to={`/rates`}>
              {t('rates')}
            </Link>
            <div className="header-content-border-bottom"></div>
          </div>

          <div className="header-content-tab" style={{ display: width > 800 ? 'flex' : 'none' }}>
            {cartCount > 0 && <div className="badge">{cartCount}</div>}
            <Link className="link" to={`/cart`}>
              {t('cart')}
            </Link>
            <div className="header-content-border-bottom"></div>
          </div>
          <div className="header-content language-container link" style={{ display: width > 800 ? 'flex' : 'none' }}>
            <div className={`lng-container`} style={{ display: width > 800 ? 'flex' : 'none' }}>
              <div className="header-content-tab">
                <p onClick={() => handleSelectLng('Eng')} className={activetab.toLowerCase() === 'eng' ? 'active' : ''}>
                  Eng
                </p>
                <div className="header-content-border-bottom"></div>
              </div>
              <div className="border"></div>
              <div className="header-content-tab">
                <p onClick={() => handleSelectLng('Rus')} className={activetab.toLowerCase() === 'rus' ? 'active' : ''}>
                  Рус
                </p>
                <div className="header-content-border-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-menu-container link">
        <div className="header-menu-container_content">
          <div className="header-content-tab">
            {cartCount > 0 && <div className="badge">{cartCount}</div>}
            <Link className="link" to={`/cart`} onClick={closeHeaderModal}>
              {t('cart')}
            </Link>
            <div className="header-content-border-bottom"></div>
          </div>
          {width > 800 && (
            <>
              <div className="header-content-tab">
                <Link className="link" to={`/rates`} onClick={closeHeaderModal}>
                  {t('becomePartner')}
                </Link>
                <div className="header-content-border-bottom"></div>
              </div>
              <div className="header-content-tab">
                <Link className="link" to={`/rates`} onClick={closeHeaderModal}>
                  {t('rates')}
                </Link>
                <div className="header-content-border-bottom"></div>
              </div>
              <div className="header-content-tab">
                <Link className="link" to={`/blog`} onClick={closeHeaderModal}>
                  {t('blog')}
                </Link>
                <div className="header-content-border-bottom"></div>
              </div>
              <div className="header-content-tab">
                <Link className="link" to={`/referrer`}>
                  {t('becomePartner')}
                </Link>
                <div className="header-content-border-bottom"></div>
              </div>
            </>
          )}
          <div className="header-menu-icon" onClick={() => toogleList()}>
            <div className="header-icon" style={{ width: 22, height: 30 }}>
              <img src="/images/icons/menu.png" alt="menu" />
            </div>
          </div>
        </div>
      </div>
      {width <= 800 && (
        <HeaderModal
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          cities={cities}
          setCity={setLocationAction}
          resetCategories={resetCategoriesAction}
          lng={lng}
          setLng={setLngAction}
          setOrderData={setOrderDataAction}
        />
      )}

      <div className="user-icon-container"></div>
    </header>
  );
};

export default Header;
