import { createReducer } from 'deox';
import produce from 'immer';
import { sliderImages } from 'src/configs/images';
import { shuffle } from 'src/utils/helpers';
import { resetImagesArr, setImagesArr, setLng, setLocation } from './actions';
import { appState } from './types';

export const appInitialState: appState = {
  location: '',
  lng: localStorage.selectedLanguage || 'Eng',
  imagesArr: sliderImages,
  cities: ['Sharm El Sheikh', 'Hurghada', 'Cairo', 'Aswan'],
  languages: ['Eng', 'Rus'],
};

export const appReducer = createReducer(appInitialState, (handle) => [
  handle(setImagesArr, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.imagesArr = payload;
    })
  ),
  handle(resetImagesArr, (state) =>
    produce(state, (draft) => {
      draft.imagesArr = shuffle(sliderImages);
    })
  ),
  handle(setLng, (state, { payload }: any) =>
    produce(state, (draft) => {
      localStorage.setItem('selectedLanguage', payload);
      draft.lng = payload;
    })
  ),
  handle(setLocation, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.location = payload;
    })
  ),
]);
