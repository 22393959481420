import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ContactUs from '../ContactUs';
import SocialIcons from '../SocialIcons';
import './styles.scss';

type Props = {
  newClass?: string;
  handleClick?: () => void;
};

const Footer: FC<Props> = ({ newClass, handleClick }) => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  const [visible, setVisible] = useState(false);
  const close = (): void => {
    setVisible(false);
  };
  const toogleModal = (): void => {
    setVisible(true);
    if (handleClick) {
      handleClick();
    }
  };
  return (
    <footer className={`footer-container  ${newClass}`}>
      <div className="trustPilot-container">
        <div
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="56278e9abfbbba0bdcd568bc"
          data-businessunit-id="6738b7067a770a6694f7566a"
          data-style-height="52px"
          data-style-width="150px"
        >
          <a href="https://uk.trustpilot.com/review/tourest.online" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      </div>
      <SocialIcons color="#fff" className="footer-icon" />
      <p className="footer-text">
        {t('copyright')} &copy; {year} TouRest.online{' '}
      </p>
      <p className="footer-text">{t('copyright')}.</p>
      <div className="footer-navigation">
        <Link to={`/about`} className="footer-nav" onClick={handleClick}>
          {t('about')}
        </Link>
        <Link to={`/privacy`} className="footer-nav" onClick={handleClick}>
          {t('privacyPolicy')}
        </Link>
        <div className="footer-nav" onClick={() => toogleModal()}>
          {t('contact')}
        </div>
      </div>
      <ContactUs visible={visible} close={close} />
    </footer>
  );
};

export default Footer;
