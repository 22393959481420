/* eslint-disable max-len */
import { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/atoms/SvgIcon';
import SocialIcons from 'src/components/common/SocialIcons';
import './styles.scss';

const Referrer: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="container referrer_container">
      <HelmetProvider>
        <Helmet>
          <title>Become affilate partner with Tourest.online</title>
          <meta
            name="description"
            content="Partner with Tourest.online and earn commissions by promoting authentic tours in Egypt. Enjoy a hassle-free referral system, transparent reporting, flexible payment options, and dedicated support. Join now and grow with us!"
          />
          <meta
            name="keywords"
            content="Partnership program, earn commissions online, tour booking affiliate, referral program for tours, travel affiliate program, Egypt tour partnership, tour referral system, partner with Tourest, online tour commission, affiliate marketing for travel, make money with referrals, authentic Egypt tours"
          />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div className="referrer_container_baner">
        <img src="/images/icons/pyramids.png" alt="Egypt Pyramids" className="referrer_container_baner_firstImg" />
        <h1>{t('referrerBannerText')}</h1>
        <img src="/images/icons/sea.png" alt="Red sea" className="referrer_container_baner_lastImg" />
      </div>
      <div className="referrer_image_container">
        <img src="/images/referrer.jpg" alt="Become a referrer" className="referrer_image" />
        <div className="referrer_text_overlay">
          <h2>Why Collaborate with Tourest.online?</h2>
          <ul>
            <li>
              <strong>Trusted Platform:</strong> Partnering with local operators for high-quality, authentic tours.
            </li>
            <li>
              <strong>Flexible & Transparent:</strong> Easily track your progress through our tools.
            </li>
            <li>
              <strong>Rewarding Partnership:</strong> Scalable commission system with room for growth.
            </li>
            <li>
              <strong>Dedicated Support:</strong> A team ready to assist at every step.
            </li>
          </ul>
          <p className="closing-text">Let’s Grow Together!</p>
          <p>
            We believe this collaboration will be mutually rewarding and are committed to ensuring a seamless and
            beneficial partnership. Let us know if you have any questions or are ready to proceed!
          </p>
        </div>
      </div>
      <div className="referrer_container_baner aboutUs">
        <h2>About Us </h2>
        <ul>
          <li>
            <h4>Tourest.online is a dynamic platform connecting tour operators with travelers.</h4>
          </li>
          <li>
            <h4>
              Our main office is located in Yerevan, Armenia, while our trusted partners who organize the tours—are
              based in Egypt.
            </h4>
          </li>
          <li>
            <h4>
              We focus on delivering authentic, unforgettable experiences to travelers while supporting local tour
              operators.
            </h4>
          </li>
        </ul>
      </div>
      <div className="referrer_container_howItWorks">
        <h2>Make money through your website or blog</h2>
        <div className="card_container">
          <div className="card">
            <SvgIcon src="/images/icons/dollar.svg" />
            <h4>Referral System</h4>
            <ul>
              <li>We’ll provide you with a unique referral link to share with your audience.</li>
              <li>Every booking made through your referral link earns you a commission.</li>
            </ul>
          </div>
          <div className="card">
            <SvgIcon src="/images/icons/hassle.svg" />
            <h4>Hassle-Free Process</h4>
            <ul>
              <li>
                Your role is simple: refer customers. We handle everything else: bookings, customer service, and tour
                management.
              </li>
            </ul>
          </div>
          <div className="card">
            <SvgIcon src="/images/icons/reporting.svg" />
            <h4>Transparent Reporting</h4>
            <ul>
              <li>
                Access our back-office tool to track visits, bookings, order statuses, and commissions in real time.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="referrer_container_commission">
        <h2>Examples of commissions</h2>
        <div>
          <div className="referrer_container_commission_item">
            <h4 className="referrer_container_commission_item_level">Leavel #start</h4>
            <h4 className="referrer_container_commission_item_value">{'<'} 50 Sold tour --- 5% commission</h4>
          </div>
          <div className="referrer_container_commission_item">
            <h4 className="referrer_container_commission_item_level">Leavel #Master</h4>
            <h4 className="referrer_container_commission_item_value">{'<'} 75 Sold tour --- 7% commission</h4>
          </div>
          <div className="referrer_container_commission_item">
            <h4 className="referrer_container_commission_item_level">Leavel #Expert</h4>
            <h4 className="referrer_container_commission_item_value"> 75-100 Sold tour --- 10% commission</h4>
          </div>
          <div className="referrer_container_commission_item">
            <h4 className="referrer_container_commission_item_level">Leavel #TeamMember</h4>
            <h4 className="referrer_container_commission_item_value"> 100+ Sold tour --- Unique offer</h4>
          </div>
        </div>
      </div>
      <div className="referrer_container_howItWorks">
        <h2>Payment options</h2>
        <div className="card_container">
          <div className="card">
            <SvgIcon src="/images/icons/dollar.svg" />
            <h4>Cryptocurrency</h4>
            <ul>
              <li>Preferred: USDT (other cryptocurrencies can be discussed)</li>
            </ul>
          </div>
          <div className="card">
            <SvgIcon src="/images/icons/dollar.svg" />
            <h4>Electronic Payments</h4>
            <ul>
              <li>We can transfer funds via your preferred payment platform.</li>
            </ul>
          </div>
          <div className="card">
            <SvgIcon src="/images/icons/dollar.svg" />
            <h4>Cash (Egypt, Armenia, Georgia Only)</h4>
            <ul>
              <li>Available for partners based in Egypt.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="referrer_container_contactUs">
        <h2>Contact us</h2>
        <SocialIcons className="socialIcons" color="#000" />
        <div>
          <div className="socialIcons" style={{ alignItems: 'center' }}>
            <SvgIcon src="/images/icons/telegram.svg" className="contact_icon" />
            <SvgIcon src="/images/icons/viber.svg" className="contact_icon" />
            <SvgIcon src="/images/icons/whatsapp.svg" className="contact_icon" />
            <span>+37493245235</span>
          </div>
          <a href="mailto:info@tourest.online">info@tourest.online</a>
        </div>
      </div>
    </div>
  );
};

export default Referrer;
