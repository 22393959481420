/* eslint-disable max-len */
// RtlSlider.tsx
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { IRootState } from 'src/types/store-types';
import './styles.scss';

const SlickSlider: React.FC = () => {
  const mainSliderRef = useRef<Slider | null>(null);
  const navSliderRef = useRef<Slider | null>(null);

  const { imagesArr } = useSelector((state: IRootState) => state.app);

  const mainSliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: navSliderRef.current || undefined,
    beforeChange: (_current: number, next: number) => {
      const slides = document.querySelectorAll('.slick-slide');
      slides.forEach((slide, index) => {
        if (index !== next) {
          slide.setAttribute('tabindex', '-1');
        }
      });
    },
    afterChange: (current: number) => {
      const activeSlide = document.querySelector(`.slick-slide[data-index="${current}"]`);
      activeSlide?.setAttribute('tabindex', '0');
    },
  };

  const navSliderSettings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    focusOnSelect: true,
    asNavFor: mainSliderRef.current || undefined, // Ensures it's undefined if null
    prevArrow: (
      <div className="thumb-prev">
        <i className="fa fa-angle-up fa-lg"></i>
      </div>
    ),
    nextArrow: (
      <div className="thumb-next">
        <i className="fa fa-angle-down fa-lg"></i>
      </div>
    ),
  };

  return (
    <div className="rtl-slider-flex">
      {/* Main Slider */}
      <div className="rtl-slider">
        <Slider ref={(slider) => (mainSliderRef.current = slider)} {...mainSliderSettings}>
          {imagesArr.map((slide, index) => (
            <img key={index} className="rtl-slider-slide" src={slide} />
          ))}
        </Slider>
      </div>

      {/* Navigation Slider */}
      <div className="rtl-slider-nav">
        <Slider ref={(slider) => (navSliderRef.current = slider)} {...navSliderSettings}>
          {imagesArr.map((slide, index) => (
            <img key={index} src={slide} className="rtl-slider-slide" />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SlickSlider;
