import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Empty, Input, Spin } from 'antd';
import cn from 'classnames';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'src/hooks/use-debounce';
import useOutsideClick from 'src/hooks/useOutSideClick';
import { getToursByName } from 'src/services/ToursService';
import { IRootState } from 'src/types/store-types';
import { SUCCESS_STATUSES } from 'src/utils/constants';
import { getCity, getLngKey, makePath } from 'src/utils/helpers';
import './styles.scss';

const DEFAULT_FILTERS = '';

type IProps = {
  classname?: string;
};

const SearchInput: FC<IProps> = ({ classname }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { location, lng } = useSelector((state: IRootState) => state.app);

  // States
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(DEFAULT_FILTERS);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  // Hooks
  const debouncedSearchValue: any = useDebounce(searchValue, 500);

  // Ref for the modal
  const modalRef = useRef<HTMLDivElement>(null);

  // Close modal on outside click
  useOutsideClick(modalRef, () => {
    if (showModal) {
      onCloseModal();
    }
  });

  // API Calls
  const getDataReq = async (): Promise<void> => {
    setLoading(true);
    const res = await getToursByName(debouncedSearchValue, lng.toLowerCase(), getCity(location));
    if (SUCCESS_STATUSES.includes(res.status)) {
      setData(res.data);
    }
    setLoading(false);
  };

  // Actions
  const onOpenModal = (): void => {
    setShowModal((prev: boolean) => !prev);
  };

  const onCloseModal = (): void => {
    setSearchValue(DEFAULT_FILTERS);
    setData([]);
    setShowModal(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleNavigateToTour = (tourName: string, id: string): void => {
    tourName = makePath(tourName);
    navigate(`/tour-booking?name=${tourName}&id=tour_${id}`);
    onCloseModal();
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      getDataReq();
    }
  }, [debouncedSearchValue]);

  return (
    <div className={cn('search_input_wrapper', classname)}>
      <Button className="search_button" type="text" icon={<SearchOutlined />} onClick={onOpenModal} />
      <div className={cn('search_modal', { ['show_modal']: showModal })} ref={modalRef}>
        <div className="search_modal_content">
          <div className="search_modal_content_header">
            <Input
              className="search_input_item"
              name="searchValue"
              onChange={handleChange}
              value={searchValue}
              suffix={<SearchOutlined />}
            />
            <Button
              onClick={onCloseModal}
              className="search_modal_close_button"
              color="default"
              shape="circle"
              icon={<CloseOutlined />}
            />
          </div>
          <div className="search_modal_content_divider">
            <div className="search_modal_content_divider_line"></div>
          </div>
          <div className="search_modal_content_body">
            <h3 className="search_modal_content_body_title">{t('tours')}</h3>
            <div className="search_modal_result_container">
              {loading && <Spin className="search_modal_loader" />}
              {!loading && (!data || data.length === 0) && (
                <div className="search_modal_no_result">
                  <Empty />
                </div>
              )}
              {!loading && data && data.length > 0 && (
                <div className="search_modal_items_list">
                  {data.map((el: any) => (
                    <div
                      key={el.id}
                      className="search_modal_items_list_item"
                      onClick={() => handleNavigateToTour(el[`${getLngKey(lng)}_name`], el.id)}
                    >
                      <div className="search_list_item_image">
                        <img src={el.images[0]} alt={el[`${getLngKey(lng)}_name`]} />
                      </div>
                      <div className="search_list_item_description">
                        <p className="search_list_item_title">{el[`${getLngKey(lng)}_name`]}</p>
                        <p className="search_list_item_desc">{el[`${getLngKey(lng)}_shortDescription`]}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
