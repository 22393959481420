import { FC } from 'react';
import SlickSlider from 'src/components/common/SlickSlider';
// import Slider from 'src/components/common/Slider';
// import Tabs from 'src/components/common/Tabs';

const WithSliderLayout: FC<any> = ({ children }) => {
  // const isTaxiPage = window.location.pathname.includes('taxi');
  return (
    <>
      {/* <Slider isBlog={isBlog} /> */}
      <SlickSlider />
      {/* {!(isTaxiPage || isBlog) && <Tabs />} */}
      {children}
    </>
  );
};

export default WithSliderLayout;
