/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-len */
import { message } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import moment from 'moment';
import queryString from 'query-string';

export const isEmail = (str: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(str).toLowerCase());
};

const isSuccess = (r: { success: boolean }): boolean => r?.success;

const updateInArray = (array: any[], findFn: (el?: any) => boolean, updateFn: (el: any) => void): any => {
  const index = array.findIndex(findFn);
  const elem = array.find(findFn);
  const newArray: any[] = [...array];
  newArray.splice(index, 1, updateFn(elem));
  return newArray;
};
const removeFromArray = (array: any[], findFn: (i?: any) => boolean): any[] => {
  const index = array.findIndex(findFn);
  const newArray = [...array];
  newArray.splice(index, 1);
  return newArray;
};

const getExtension = (fileName: string): string => {
  const splittedFilaName = fileName.split('.');

  if (splittedFilaName[0].length === fileName.length) {
    return 'font';
  }

  return splittedFilaName[splittedFilaName.length - 1];
};

const verifyTokenExpiration = (): boolean => {
  const now = moment();
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) : null;
  if (user) {
    const storedDate = now.add(user.exp, 'seconds').valueOf();
    const currentDate = new Date();
    return Boolean(storedDate) && storedDate - currentDate.valueOf() > 0;
  }

  return false;
};

export const capitalizeFirstLetter = (string: string): string => string?.charAt(0).toUpperCase() + string.slice(1);

const getField = (values: any[], fieldName: string): any =>
  values.find((targetObj: any) => targetObj.hasOwnProperty(fieldName));

const getBase64 = (img: RcFile, callback: (url: string) => void): void => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const makePath = (str: string): string => str?.toLowerCase().split(' ').join('-');

const makeSelectOption = (value: string | any, label?: string): SelectOptionType => ({ label: label || value, value });

const getValueFromSelecOption = (opt: SelectOptionType): string | number => opt.value;

const makeQueryString = (queryObj: any): string => {
  let query = '';
  Object.keys(queryObj).forEach((key) => {
    query += `&${key}=${queryObj[key]}`;
  });
  return query;
};

export const getCity = (city: string): string => {
  city = makePath(city);
  city = city.toLowerCase();
  switch (city) {
    case 'sharm-el-sheikh':
      return 'Sharm El Sheikh';
    case 'aswan':
      return 'Aswan';
    case 'hurghada':
      return 'Hurghada';
    case 'cairo':
      return 'Cairo';
    default:
      return city;
  }
};

export const startTimeConverter = (str: string): string => {
  const [hours, minutes] = str.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const convertedHours = hours % 12 || 12; // Convert 0 to 12 for midnight

  return `${convertedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
};

export const getIdFromPath = (path: string, separator: string, index = 1): string => path.split(separator)[index];

export const getLngKey = (str: string): string => str.substring(0, str.length - 1).toLowerCase();

const makeQueryObj = (queryStr: string): any => {
  const obj = queryString.parse(queryStr);

  if (typeof obj?.hierarchyIds === 'string') {
    obj.hierarchyIds = [obj.hierarchyIds];
  }

  if (typeof obj?.productIds === 'string') {
    obj.productIds = [obj.productIds];
  }

  if (typeof obj?.transactionTypes === 'string') {
    obj.transactionTypes = [obj.transactionTypes];
  }

  if (obj.dateFrom) {
    obj.dateFrom = new Date(Number(obj.dateFrom));
  }

  if (obj.dateTo) {
    obj.dateTo = new Date(Number(obj.dateTo));
  }

  if (obj.occurrence) {
    obj.occurrence = obj.occurrence === 'true';
  }

  if (obj.dateCreated && obj.dateCreated === 'useGlobalDateRange') {
    obj.dateCreated = {
      from: new Date(Number(obj.dateFrom)),
      to: new Date(Number(obj.dateTo)),
      useGlobalDateRange: true,
    };
  }

  return obj;
};

const isTokenExpired = (): boolean => {
  const storedUser: any = localStorage.getItem('user');
  if (storedUser) {
    const tokenExpDate = parseInt(JSON.parse(storedUser).expAt, 10);
    return moment().valueOf() >= tokenExpDate * 1000;
  }
  return true;
};

const isNumber = (input: any): boolean => {
  if (typeof input === 'number') {
    return !isNaN(input) && isFinite(input);
  }
  if (typeof input === 'string' && input.trim() !== '') {
    return !isNaN(parseFloat(input)) && isFinite(parseFloat(input));
  }
  return false;
};

const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export const isValidObject = (obj: any): any => {
  for (const [key, value] of Object.entries(obj)) {
    // Skip validation for 'referralLink'
    if (key === 'referralLink') {
      continue;
    }
    // Check if value is missing or empty
    if (value === undefined || value === '') {
      delete obj[key]; // Remove undefined or empty key-value pair
    }
  }

  // Check again to ensure there are no missing or empty values
  for (const [key, value] of Object.entries(obj)) {
    if (!value && key !== 'referralLink') {
      return message.error(`Error: The field "${key}" is not filled.`);
    }
  }

  return true;
};

const isValidEmail = (email: string): boolean => {
  //  eslint-disable-next-line
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

// Initial version for error handling
const getParamsString = (params: any, t: (k: string, p?: any) => string): string | string[] => {
  if (Array.isArray(params)) {
    return params
      .map((param) => {
        if (typeof param === 'object') {
          return Object.keys(param).map((key) => `${String(t(key)).toLowerCase()} ${param[key]}`);
        }

        return t(param);
      })
      .join(',');
  }
  if (typeof params === 'object') {
    return Object.keys(params).map((key) => `${String(t(key)).toLowerCase()} ${params}`);
  }

  return t(params);
};

const shuffle = (array: string[]): string[] => [...array].sort(() => Math.random() - 0.5);

const scrollToTop = (): void => {
  window.scrollTo({
    left: 0,
    top: 0,
  });
};

const makeOrderPayload = (data: any): any => {
  const formattedDate = data.firstDate.replace(/\//g, '-');
  const firstTourDate = moment
    .parseZone(formattedDate)
    .set({
      hour: parseInt(data.startTime.split(':')[0], 10),
      minute: parseInt(data.startTime.split(':')[1], 10),
      second: parseInt(data.startTime.split(':')[2], 10),
    })
    .format('YYYY-MM-DDTHH:mm:ssZ');

  const payload: any = {
    user: {
      email: data.email,
      firstName: data.name.split(' ')[0] || data.name,
      lastName: data.name.split(' ')[1] || data.name,
      hotel: data.hotel,
      room: data.room,
      contact: data.contact,
      phoneNumber: data.phoneNumber,
    },
    city: data.city,
    firstTourDate,
    lastTourDate: moment(data.lastDate).format('YYYY-MM-DD'),
    paymentType: data.paymentMethod === 'cash' ? 'cash' : 'card',
    tickets: data.tickets,
  };
  if (data.referralLink) {
    payload.referralLink = data.referralLink;
  }
  return payload;
};
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());
  return `${day} / ${month} / ${year}`;
};

export {
  makeOrderPayload,
  formatDate,
  shuffle,
  getParamsString,
  updateInArray,
  removeFromArray,
  getExtension,
  verifyTokenExpiration,
  getField,
  getBase64,
  makeSelectOption,
  getValueFromSelecOption,
  isSuccess,
  makeQueryString,
  makeQueryObj,
  isTokenExpired,
  isNumber,
  capitalize,
  isValidEmail,
  scrollToTop,
};
